export class Banner {
    
    id: number = null;
    titulo: string = '';
    subTitulo: string = '';
    descricao: string = '';
    ordem: number = 99;
    imagem: string = '';
    situacao: string = 'A';
    link: string = '';
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}