import { HelperService } from './services/helper.service';
import { Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let keyPressed = event.keyCode;
    if (keyPressed === 27) {

    }
  }

  constructor(
    public auth: AuthService,
    public api: ApiService,
    public router: Router,
    public helper: HelperService
  ) {
    this.auth.setToken(localStorage.getItem('token_blackdragons'));
    this.auth.user = this.auth.getUserStorage();
    this.api.httpStatus.subscribe(status => {
      if (status === 401) {
        this.router.navigate(['/login']).then(() => {
          this.auth.logout();
        })
      }
    });
    this.api.httpError.subscribe(error => this.helper.openSnackBar(error));
  }
}
