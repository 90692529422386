import { ModalNotificacaoComponent } from './../../components/modal-notificacao/modal-notificacao.component';
import { NotificacaoService, NotificacoesApi } from './../../services/class/notificacao.service';
import { Notificacao } from './../../models/notificacao.model';
// default
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from './../../services/global.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss']
})
export class NotificacoesComponent implements OnInit {

  displayedColumns: string[] = ['select', 'imagem', '1', '2', '3', '4', 'actions'];
  // imagem, titulo, subtitulo, tipo notificacao, situacao
  data: Notificacao[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  selection = new SelectionModel<Notificacao>(true, []);

  constructor(
    public global: GlobalService,
    public notificacaoService: NotificacaoService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.buscarNotificacoes();
  }

  buscarNotificacoes() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.notificacaoService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase());
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.notificacoes;
        }),
        catchError((err) => {
          console.log('entrei aq: ', err);
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  deletarNotificacao(notificacao: Notificacao) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir notificação',
        description: 'Você realmente quer excluir essa notificação? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.present('Excluindo notificação...');
        this.notificacaoService.delete(notificacao).subscribe((res: any) => {
          this.helper.openSnackBar('Notificação removida com sucesso.');
          this.loadingService.dismiss();
          this.buscarNotificacoes();
        }, e => this.loadingService.dismiss());
      }
    })
  }

  deletarNotificacoes() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir notificações',
        description: 'Você realmente quer excluir essas notificações? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.notificacaoService.deleteSelected(this.selection.selected).subscribe((res: any) => {
          this.loadingService.present('Excluindo notificações');
          this.helper.openSnackBar('Notificações removidas com sucesso.');
          this.loadingService.dismiss();
          this.buscarNotificacoes();
        }, e => this.loadingService.dismiss());
      }
    })
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.notificacaoService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), e.toLocaleLowerCase())
        .subscribe((res: NotificacoesApi) => {
          this.data = this.paginator.pageIndex == 0 ? res.notificacoes : this.data.concat(res.notificacoes);
        });
    } else {
      this.buscarNotificacoes();
    }
  }

  enviarNotificacao(notificacao: Notificacao) {
    this.loadingService.present('Enviando push...');

    this.notificacaoService.push(notificacao).subscribe((res: any) => {
      this.helper.openSnackBar('Notificação enviada.')
      this.loadingService.dismiss();
    }, e => this.loadingService.dismiss())
  }

  clearFilter() {
    this.search = '';
    this.buscarNotificacoes();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: Notificacao): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  teste(notificacao: Notificacao) {
    const dialogRef = this.dialog.open(ModalNotificacaoComponent, {
      width: '420px',
      data: {
        notificacao: notificacao
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
