import { Time } from './../../models/time.model';
import { ApiService } from './../api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<TimesApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/time/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/time/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/time/buscar?id=${id}`);
  }

  post(time: Time): Observable<any> {
    return this.api.post('/time/inserir', time);
  }

  patch(time: Time): Observable<any> {
    return this.api.post('/time/alterar', time);
  }

  delete(time: Time): Observable<any> {
    return this.api.post('/time/deletar', time);
  }

  deleteSelected(times: Time[]): Observable<any> {
    return this.api.post('/time/deletarLista', times);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Time, v2: Time): boolean {
    return compareFn(v1, v2);
  }
}

export interface TimesApi {
  times: Time[];
  numeroPaginas: number;
}

function compareFn(v1: Time, v2: Time): boolean {
  return v1 && v2 ? v1.id === v2.id : v1 === v2;
}
