import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange, MatCheckboxChange } from '@angular/material';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { JogoService } from './../../services/class/jogo.service';
import { Jogo } from './../../models/jogo.model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-jogos-editar',
  templateUrl: './jogos-editar.component.html',
  styleUrls: ['./jogos-editar.component.scss']
})
export class JogosEditarComponent implements OnInit {

  jogo: Jogo = new Jogo();
  buscarJogoSubscription: Subscription;

  file: File;
  fileIcone: File;
  progress: number = 0;

  loading: boolean = false;

  constructor(
    public jogoService: JogoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.loading = true;
      this.buscarJogoSubscription = this.jogoService.getById(param.id)
        .subscribe((res: Jogo) => {
          this.jogo = res;
          this.loading = false;
        }, e => this.loading = false);
    })
  }

  ngOnDestroy() {
    this.buscarJogoSubscription.unsubscribe();
  }

  alterarJogo(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando jogo...');
    this.jogo.slug = this.helper.convertToSlug(this.jogo.nome);

    this.jogoService.patch(this.jogo)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/jogo/imagem?id=${this.jogo.id}`);
        this.submitImages(this.fileIcone, `/jogo/icone?id=${this.jogo.id}`);
        this.router.navigate(['/jogos']).then(() => {
          this.helper.openSnackBar('Jogo alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.jogo.situacao = 'A' : this.jogo.situacao = 'I';
  }

  onBattleRoyaleChange(event: MatCheckboxChange) {
    event.checked ? this.jogo.battleRoyale = 'S' : this.jogo.battleRoyale = 'N';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%')
    this.submitImages(this.file, '/jogo/imagem?id=-99').then((res: any) => {
      this.jogo.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  @ViewChild('fileIconeInput', { static: true }) fileIconeInput: ElementRef;
  onFileIconeSelected(files) {
    this.fileIcone = files.item(0);
    this.loadingService.present('0%')
    this.submitImages(this.fileIcone, '/jogo/icone?id=-99').then((res: any) => {
      this.jogo.icone = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileIconeInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.jogoService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.jogo.imagem = '';
  }

  removeIcone() {
    this.jogo.icone = '';
  }

}
