export class Patrocinador {

    id: number;
    nome: string;
    descricao: string;
    imagem: string = '';
    situacao: string = 'A';
    linkSite: string = '';
    background: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}