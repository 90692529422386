export class Jogador {

    id: number;
    nome: string = '';
    apelido: string = '';
    posicao: string = '';
    descricao: string = '';
    situacao: string = 'A';
    foto?: string = '';
    linkInstagram?: string = '';
    linkFacebook?: string = '';
    linkTwitter?: string = '';
    idTime: number;
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}