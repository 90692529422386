export class ContatoGeral {
    id: number;
    linkInstagram?: string = '';
    linkTwitter?: string = '';
    linkFacebook?: string = '';
    linkYoutube?: string = '';
    linkAppStore?: string = '';
    linkPlayStore?: string = '';
    email: string;
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}