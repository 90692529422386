import { Jogo } from './jogo.model';

export class Pergunta {

    id: number = null;
    jogo: Jogo = new Jogo();
    descricao: string = '';
    situacao: string = 'A';
    imagem: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}