import { Jogador } from './../../models/jogador.model';
import { JogadorService } from './../../services/class/jogador.service';
import { Time } from './../../models/time.model';
import { TimeService, TimesApi } from './../../services/class/time.service';
// default
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-jogadores-criar',
  templateUrl: './jogadores-criar.component.html',
  styleUrls: ['./jogadores-criar.component.scss']
})
export class JogadoresCriarComponent implements OnInit {

  jogador: Jogador = new Jogador();
  times: Time[] = [];

  buscarTimesSubscription: Subscription;

  file: File;

  progress: number = 0;

  constructor(
    public timeService: TimeService,
    public jogadorService: JogadorService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
    this.buscarTimes();
  }

  ngOnDestroy() {
    this.buscarTimesSubscription.unsubscribe();
  }

  inserirJogador(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo jogador...');

    this.jogadorService.post(this.jogador)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/jogador/foto?id=${res}`);
        this.router.navigate(['/jogadores']).then(() => {
          this.helper.openSnackBar('Jogador inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  buscarTimes() {
    this.buscarTimesSubscription = this.timeService.get(-99, -99, '1', 'ASC')
      .subscribe((res: TimesApi) => {
        this.times = res.times;
      });
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.jogador.situacao = 'A' : this.jogador.situacao = 'I';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/jogador/foto?id=-99').then((res: any) => {
      this.jogador.foto = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.jogadorService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  } 

  removeFoto() {
    this.jogador.foto = '';
  }

}
