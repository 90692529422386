import { Jogador } from './../../models/jogador.model';
import { ApiService } from './../api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JogadorService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<JogadoresApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/jogador/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/jogador/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/jogador/buscar?id=${id}`);
  }

  post(jogador: Jogador): Observable<any> {
    return this.api.post('/jogador/inserir', jogador);
  }

  patch(jogador: Jogador): Observable<any> {
    return this.api.post('/jogador/alterar', jogador);
  }

  delete(jogador: Jogador): Observable<any> {
    return this.api.post('/jogador/deletar', jogador);
  }

  deleteSelected(jogadores: Jogador[]): Observable<any> {
    return this.api.post('/jogador/deletarLista', jogadores);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface JogadoresApi {
  jogadores: Jogador[];
  numeroPaginas: number;
}
