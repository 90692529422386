class Autenticacao {
  sucesso: boolean;
  erro: string;
}

export class Usuario {
  id: number;
  bairro: string = "";
  cep: string = "";
  cidade: string = "";
  codigoRecuperacao: string = "";
  complemento: string = "";
  cpf: string = "";
  dataNascimento: string = "";
  email: string = "";
  endereco: string = "";
  idLoginExterno: string = "";
  imagemPerfil: string = "";
  nick: string = "";
  nome: string = "";
  numeroEndereco: string = "";
  pais: string = "";
  playerId: string = "";
  senha: string = "";
  sexo: string = "M";
  situacao: string = "A";
  sobrenome: string = "";
  solicitaAlteracao: string = "";
  telefone: string = "";
  tipoLoginExterno: string = "";
  tipoUsuario: string = "A";
  uf: string = "";
  autenticacao: Autenticacao = new Autenticacao();
  [x: string]: any;

  constructor(obj?) {}
}
