import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleChange } from '@angular/material';
import { Resposta } from 'src/app/models/resposta.model';
import { LoadingService } from 'src/app/services/loading.service';
import { RespostaService } from 'src/app/services/class/resposta.service';
import { HttpProgressEvent } from '@angular/common/http';

@Component({
  selector: 'app-modal-editar-resposta',
  templateUrl: './modal-editar-resposta.component.html',
  styleUrls: ['./modal-editar-resposta.component.scss']
})
export class ModalEditarRespostaComponent implements OnInit {

  resposta: Resposta = new Resposta();

  file: File;
  progress: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ModalEditarRespostaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loadingService: LoadingService,
    public respostaService: RespostaService
  ) {
    this.resposta = data;
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(this.resposta);
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.resposta.situacao = 'A' : this.resposta.situacao = 'I';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/resposta/imagem?id=-99').then((res: any) => {
      this.resposta.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.respostaService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.resposta.imagem = '';
  }

}
