import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, SubscriptionLike } from 'rxjs';
import { Jogo } from './../../models/jogo.model';
import { JogoService, JogosApi } from './../../services/class/jogo.service';
import { TimeService } from './../../services/class/time.service';
import { Time } from './../../models/time.model';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-times-editar',
  templateUrl: './times-editar.component.html',
  styleUrls: ['./times-editar.component.scss']
})
export class TimesEditarComponent implements OnInit {

  time: Time = new Time();
  jogos: Jogo[] = [];

  buscarTimeSubscription: Subscription;
  buscarJogosSubscription: Subscription;

  fileImagemPequena: File;
  fileImagemGrande: File;

  progress: number = 0;

  loading: boolean = false;

  constructor(
    public timeService: TimeService,
    public jogoService: JogoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(param => {
      this.buscarTimeSubscription = this.timeService.getById(param.id)
        .subscribe((res: Time) => {
          this.time = res;
          this.loading = false;
        }, e => this.loading = false);
    })
    this.buscarJogos();
  }

  ngOnDestroy() {
    this.buscarTimeSubscription.unsubscribe();
    this.buscarJogosSubscription.unsubscribe();
  }

  alterarTime(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando time...');
    this.time.slug = this.helper.convertToSlug(this.time.nome);

    this.timeService.patch(this.time)
      .subscribe((res: any) => {
        this.submitImages(this.fileImagemPequena, `/time/imagemPequena?id=${this.time.id}`);
        this.submitImages(this.fileImagemGrande, `/time/imagemGrande?id=${this.time.id}`);
        this.router.navigate(['/times']).then(() => {
          this.helper.openSnackBar('Time alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  buscarJogos() {
    this.buscarJogosSubscription = this.jogoService.get(-99, -99, '1', 'ASC')
      .subscribe((res: JogosApi) => {
        this.jogos = res.jogos;
        // res.jogos.forEach(el => {
        //   if(el.id == this.time.jogo.id) {
        //     this.time.jogo = el;
        //   }
        // })
      });
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.time.situacao = 'A' : this.time.situacao = 'I';
  }

  @ViewChild('fileImagemPequenaInput', { static: true }) fileImagemPequenaInput: ElementRef;
  onFileImagemPequenaSelected(files) {
    this.fileImagemPequena = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemPequena, '/time/imagemPequena?id=-99').then((res: any) => {
      this.time.imagemPequena = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemPequenaInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  @ViewChild('fileImagemGrandeInput', { static: true }) fileImagemGrandeInput: ElementRef;
  onFileImagemGrandeSelected(files) {
    this.fileImagemGrande = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemGrande, '/time/imagemGrande?id=-99').then((res: any) => {
      this.time.imagemGrande = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemGrandeInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.jogoService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImagePequena() {
    this.time.imagemPequena = '';
  }

  removeImageGrande() {
    this.time.imagemGrande = '';
  }

}
