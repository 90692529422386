export class LinhaDoTempo {

    id: number;
    ano: string;
    descricao: string;
    [x: string]: any;
        
    constructor(obj?) {
        Object.assign(this);
    }
}