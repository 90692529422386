import { PartidaService, PartidasApi } from './../../services/class/partida.service';
import { Partida } from './../../models/partida.model';
// default
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from './../../services/global.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material';
import { Jogo } from 'src/app/models/jogo.model';
import { Liga } from 'src/app/models/liga.model';
import { JogoService } from 'src/app/services/class/jogo.service';
import { LigaService } from 'src/app/services/class/liga.service';

@Component({
  selector: 'app-partidas',
  templateUrl: './partidas.component.html',
  styleUrls: ['./partidas.component.scss']
})
export class PartidasComponent implements OnInit {

  displayedColumns: string[] = ['select', 'imagem', 'adversario', '1', '2', '3', '4', '5', '6', 'actions'];
  // imagem, liga, placar, resultado, data, jogo
  data: Partida[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  filtroTelaData: number = -99;
  dataInicial = null;
  dataFinal = null;
  idJogo: number = null;
  idLiga: number = null;

  jogos: Jogo[] = [];
  ligas: Liga[] = [];

  buscarJogosSubscription: Subscription;
  buscarLigasSubscription: Subscription;


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  selection = new SelectionModel<Partida>(true, []);

  constructor(
    public global: GlobalService,
    public partidaService: PartidaService,
    public jogoService: JogoService,
    public ligaService: LigaService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService
  ) {
    this.dataInicial = this.helper.moment().startOf('month').add(1, 'days').format('YYYY-MM-DD');
    this.dataFinal = this.helper.moment().endOf('month').format('YYYY-MM-DD');
  }

  ngOnInit() {
    this.buscarJogos();
    this.buscarLigas();
  }

  ngOnDestroy() {
    this.buscarJogosSubscription.unsubscribe();
    this.buscarLigasSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.buscar();
  }

  buscarJogos() {
    this.buscarJogosSubscription = this.jogoService.getSelect()
      .subscribe((res: Jogo[]) => this.jogos = res);
  }

  buscarLigas() {
    this.buscarLigasSubscription = this.ligaService.getAtivas()
      .subscribe((res: Liga[]) => this.ligas = res);
  }

  buscar() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.partidaService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), this.idLiga ? this.idLiga : '%', this.idJogo ? this.idJogo : '%', this.filtroTelaData, this.dataInicial ? this.dataInicial : '', this.dataFinal ? this.dataFinal : '', this.search);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.partidas;
        }),
        catchError((err) => {
          console.log('entrei aq: ', err);
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  deletarPartida(partida: Partida) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir partida',
        description: 'Você realmente quer excluir essa partida? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.present('Excluindo partida');
        this.partidaService.delete(partida).subscribe((res: any) => {
          this.helper.openSnackBar('Partida removida com sucesso.');
          this.loadingService.dismiss();
          this.buscar();
        }, e => this.loadingService.dismiss());
      }
    })
  }

  deletarPartidas() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir partidas',
        description: 'Você realmente quer excluir essas partidas? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.partidaService.deleteSelected(this.selection.selected).subscribe((res: any) => {
          this.loadingService.present('Excluindo partidas');
          this.helper.openSnackBar('Partidas removidas com sucesso.');
          this.loadingService.dismiss();
          this.buscar();
        }, e => this.loadingService.dismiss());
      }
    })
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.partidaService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), this.idLiga ? this.idLiga : '%', this.idJogo ? this.idJogo : '%', this.filtroTelaData, this.dataInicial ? this.dataInicial : '', this.dataFinal ? this.dataFinal : '', e.toLocaleLowerCase())
        .subscribe((res: PartidasApi) => {
          this.data = this.paginator.pageIndex == 0 ? res.partidas : this.data.concat(res.partidas);
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = '';
    this.buscar();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onDataInicialChange(e: any) {
    this.dataInicial = this.helper.moment(this.dataInicial).format();
    this.buscar();
  }

  onDataFinalChange(e: any) {
    this.dataFinal = this.helper.moment(this.dataFinal).format();
    this.buscar();
  }

  onFiltroTelaDataChange(e: MatSelectChange) {
    this.buscar();
  }

  onJogoChange(e) {
    this.buscar();
  }

  onLigaChange(e) {
    this.buscar();
  }

}
