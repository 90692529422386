import { Usuario } from "./../../models/usuario.model";
import { ModalResetPasswordComponent } from "./../../components/modal-reset-password/modal-reset-password.component";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { AuthService } from "./../../services/auth.service";
import { NgForm } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  user: any = {};
  isLogin: boolean = true;

  constructor(
    public auth: AuthService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  login(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loadingService.present("Fazendo o login, aguarde...");

    this.auth.login(this.user).subscribe(
      (r: Usuario) => {
        if (!r.autenticacao.sucesso) {
          this.helper.openSnackBar(r.autenticacao.erro);
          this.loadingService.dismiss();
          return;
        }

        if (r.solicitaAlteracao == "S") {
          this.auth.isRecuperarSenha = true;
          this.loadingService.dismiss();
          const dialogRef = this.dialog.open(ModalResetPasswordComponent, {
            width: "420px",
          });
          return;
        }

        // autenticacao
        this.auth.setToken(btoa(this.user.email + ":" + this.user.senha));
        this.router.navigate(["/"]);
        this.loadingService.dismiss();
        this.auth.setUser(r);
      },
      (e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      }
    );
  }

  esqueceuSenha(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha o formulário corretamente");
      return;
    }

    this.loadingService.present("Recuperando senha...");

    this.auth
      .forgotPassword(this.user)
      .then((res: any) => {
        this.helper.openSnackBar(
          "Verifique sua caixa de entrada. E-mail enviado."
        );
        f.reset();
        this.loadingService.dismiss();
      })
      .catch((e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      });
  }
}
