import { Patrocinador } from './../../models/patrocinador.model';
import { ApiService } from './../api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PatrocinadorService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<PatrocinadoresApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/patrocinador/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/patrocinador/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/patrocinador/buscar?id=${id}`);
  }

  post(patrocinador: Patrocinador): Observable<any> {
    return this.api.post('/patrocinador/inserir', patrocinador);
  }

  patch(patrocinador: Patrocinador): Observable<any> {
    return this.api.post('/patrocinador/alterar', patrocinador);
  }

  delete(patrocinador: Patrocinador): Observable<any> {
    return this.api.post('/patrocinador/deletar', patrocinador);
  }

  deleteSelected(patrocinadores: Patrocinador[]): Observable<any> {
    return this.api.post('/patrocinador/deletarLista', patrocinadores);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

}

export interface PatrocinadoresApi {
  patrocinadores: Patrocinador[];
  numeroPaginas: number;
}
