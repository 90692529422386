import { Galeria } from 'src/app/models/galeria.model';
import { Router } from '@angular/router';
import { HttpProgressEvent } from '@angular/common/http';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { GaleriaService } from './../../services/class/galeria.service';
import { JogoService, JogosApi } from './../../services/class/jogo.service';
import { Subscription } from 'rxjs';
import { Jogo } from './../../models/jogo.model';
import { ModalGaleriaService } from './../../services/modal-galeria.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-galeria',
  templateUrl: './modal-galeria.component.html',
  styleUrls: ['./modal-galeria.component.scss']
})
export class ModalGaleriaComponent implements OnInit {

  galeria: Galeria = new Galeria();

  files: File[] = [];

  jogos: Jogo[] = [];
  buscarJogosSubscription: Subscription;

  progress: number = 0;

  constructor(
    public modalGaleriaService: ModalGaleriaService,
    public jogoService: JogoService,
    public galeriaService: GaleriaService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) {
  }

  ngOnInit() {
    this.modalGaleriaService.activeSubject.subscribe(status => {

      if (this.buscarJogosSubscription && !this.buscarJogosSubscription.closed) {
        this.buscarJogosSubscription.unsubscribe();
      }

      if (status) {
        this.buscarJogos();
      }
    })
  }

  inserirGaleria(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo fotos...');

    this.submitGaleria(this.files, `/galeria/carregarGaleria?tipo=${this.galeria.tipo}&idJogo=${this.galeria.jogo.id}&plataforma=${this.galeria.plataforma}`).then(() => {
      this.router.navigate(['/comunidade']).then(() => {
        f.reset();
        this.files = [];
        this.modalGaleriaService.postSubject.next(true);
        this.loadingService.dismiss();
        this.modalGaleriaService.dismiss();
        this.helper.openSnackBar('Fotos inseridas com sucesso.');
      });
    }, e => {
      this.modalGaleriaService.postSubject.next(false);
      this.helper.openSnackBar(e.error);
      this.loadingService.dismiss()
    });

  }


  buscarJogos() {
    this.buscarJogosSubscription = this.jogoService.get(-99, -99, '1', 'ASC')
      .subscribe((res: JogosApi) => this.jogos = res.jogos);
  }

  submitGaleria(files: File[], url: string) {
    if (!files.length) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.galeriaService.postImages(files, url, {})
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        }, err => reject(err));
    });
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

}
