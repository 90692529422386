import { Jogo } from './jogo.model';
export class Noticia {

    id: number;
    titulo: string;
    tituloPush: string;
    autor: string = '';
    descricaoSimples: string = '';
    descricaoCompleta: string = '';
    slug: string = '';
    data: string = new Date().toISOString();
    situacao: string = 'A';
    imagem: string = '';
    destaque: string = 'N';
    jogo: Jogo;
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}