import { Apoiador } from './../../models/apoiador.model';
import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApoiadorService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<ApoiadoresApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/apoiador/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/apoiador/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/apoiador/buscar?id=${id}`);
  }

  post(apoiador: Apoiador): Observable<any> {
    return this.api.post('/apoiador/inserir', apoiador);
  }

  patch(apoiador: Apoiador): Observable<any> {
    return this.api.post('/apoiador/alterar', apoiador);
  }

  delete(apoiador: Apoiador): Observable<any> {
    return this.api.post('/apoiador/deletar', apoiador);
  }

  deleteSelected(apoiadores: Apoiador[]): Observable<any> {
    return this.api.post('/apoiador/deletarLista', apoiadores);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

}

export interface ApoiadoresApi {
  apoiadores: Apoiador[];
  numeroPaginas: number;
}
