import { NotificacaoService } from './../../services/class/notificacao.service';
import { Notificacao } from './../../models/notificacao.model';
// default
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange, MatCheckboxChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-notificacoes-criar',
  templateUrl: './notificacoes-criar.component.html',
  styleUrls: ['./notificacoes-criar.component.scss']
})
export class NotificacoesCriarComponent implements OnInit {

  notificacao: Notificacao = new Notificacao();

  file: File;
  progress: any;

  constructor(
    public helper: HelperService,
    public notificacaoService: NotificacaoService,
    public loadingService: LoadingService,
    public router: Router
  ) {
    this.notificacao.dataFinal = this.helper.moment(this.notificacao.dataFinal).add('years', 1).format();
    console.log(this.notificacao.dataFinal);
  }

  ngOnInit() {
  }

  inserirNotificacao(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    if (!this.verificaDias() && this.notificacao.tipoNotificacao === 'A') {
      this.helper.openSnackBar('Marque pelo menos um dia da semana.')
      return;
    }

    this.loadingService.present('Inserindo sorteio...');

    if (this.notificacao.tipoHorario === 'F') {
      this.notificacao.horaFixa = this.helper.moment(this.notificacao.horaFixa, 'HH:mm').format();
      this.notificacao.horaInicialAutomatica = null;
      this.notificacao.horaFinalAutomatica = null;
    } else {
      this.notificacao.horaFinalAutomatica = this.helper.moment(this.notificacao.horaFinalAutomatica, 'HH:mm').format();
      this.notificacao.horaInicialAutomatica = this.helper.moment(this.notificacao.horaInicialAutomatica, 'HH:mm').format();
      this.notificacao.horaFixa = null;
    }

    this.notificacaoService.post(this.notificacao)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/notificacao/imagem?id=${res}`);
        this.router.navigate(['/notificacoes']).then(() => {
          this.helper.openSnackBar('Notificação inserida com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.notificacao.horaFixa = this.helper.moment(this.notificacao.horaFixa).format('HH:mm');
        this.notificacao.horaFinalAutomatica = this.helper.moment(this.notificacao.horaFinalAutomatica).format('HH:mm');
        this.notificacao.horaInicialAutomatica = this.helper.moment(this.notificacao.horaInicialAutomatica).format('HH:mm');
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.notificacao.situacao = 'A' : this.notificacao.situacao = 'I';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, `/notificacao/imagem?id=-99`).then((res: any) => {
      this.notificacao.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.notificacaoService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.notificacao.imagem = '';
  }

  onDomingoChange(event: MatCheckboxChange) {
    event.checked ? this.notificacao.domingo = 'S' : this.notificacao.domingo = 'N';
  }

  onSegundaChange(event: MatCheckboxChange) {
    event.checked ? this.notificacao.segunda = 'S' : this.notificacao.segunda = 'N';
  }

  onTercaChange(event: MatCheckboxChange) {
    event.checked ? this.notificacao.terca = 'S' : this.notificacao.terca = 'N';
  }

  onQuartaChange(event: MatCheckboxChange) {
    event.checked ? this.notificacao.quarta = 'S' : this.notificacao.quarta = 'N';
  }

  onQuintaChange(event: MatCheckboxChange) {
    event.checked ? this.notificacao.quinta = 'S' : this.notificacao.quinta = 'N';
  }

  onSextaChange(event: MatCheckboxChange) {
    event.checked ? this.notificacao.sexta = 'S' : this.notificacao.sexta = 'N';
  }

  onSabadoChange(event: MatCheckboxChange) {
    event.checked ? this.notificacao.sabado = 'S' : this.notificacao.sabado = 'N';
  }

  verificaDias() {
    if (this.notificacao.domingo === 'N'
      && this.notificacao.segunda === 'N'
      && this.notificacao.terca === 'N'
      && this.notificacao.quarta === 'N'
      && this.notificacao.quinta === 'N'
      && this.notificacao.sexta === 'N'
      && this.notificacao.sabado === 'N') {
      return false;
    } else {
      return true;
    }
  }
}
