import { Caracteristica } from './caracteristica.model';
export class Equipamento {

    id: number;
    nome: string;
    caracteristicas: Caracteristica[];
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}