import { Liga } from './../../models/liga.model';
import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LigaService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<LigasApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/liga/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/liga/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getAtivas() {
    return this.api.get(`/liga/buscarAtiva`);
  }

  getById(id): Observable<any> {
    return this.api.get(`/liga/buscar?id=${id}`);
  }

  post(liga: Liga): Observable<any> {
    return this.api.post('/liga/inserir', liga);
  }

  patch(liga: Liga): Observable<any> {
    return this.api.post('/liga/alterar', liga);
  }

  delete(liga: Liga): Observable<any> {
    return this.api.post('/liga/deletar', liga);
  }

  deleteSelected(ligas: Liga[]): Observable<any> {
    return this.api.post('/liga/deletarLista', ligas);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Liga, v2: Liga): boolean {
    return compareFn(v1, v2);
  }
}

export interface LigasApi {
  ligas: Liga[];
  numeroPaginas: number;
}

function compareFn(v1: Liga, v2: Liga): boolean {
  return v1 && v2 ? v1.id === v2.id : v1 === v2;
}
