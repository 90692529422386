import { PatrocinadorService } from './../../services/class/patrocinador.service';
import { Patrocinador } from './../../models/patrocinador.model';
// default
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-patrocinadores-criar',
  templateUrl: './patrocinadores-criar.component.html',
  styleUrls: ['./patrocinadores-criar.component.scss']
})
export class PatrocinadoresCriarComponent implements OnInit {

  patrocinador: Patrocinador = new Patrocinador();

  file: File;
  fileBackground: File;

  progress: any;

  constructor(
    public helper: HelperService,
    public patrocinadorService: PatrocinadorService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  inserirPatrocinador(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo patrocinador...');

    this.patrocinadorService.post(this.patrocinador)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/patrocinador/imagem?id=${res}`);
        this.submitImages(this.fileBackground, `/patrocinador/background?id=${res}`);
        this.router.navigate(['/patrocinadores']).then(() => {
          this.helper.openSnackBar('Patrocinador inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.patrocinador.situacao = 'A' : this.patrocinador.situacao = 'I';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/patrocinador/imagem?id=-99').then((res: any) => {
      this.patrocinador.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  @ViewChild('fileInputBackground', { static: true }) fileInputBackground: ElementRef;
  onFileBackgroundSelected(files) {
    this.fileBackground = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileBackground, '/patrocinador/background?id=-99').then((res: any) => {
      this.patrocinador.background = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInputBackground.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.patrocinadorService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.patrocinador.imagem = '';
  }

  removeBackground() {
    this.patrocinador.background = '';
  }

}
