import { Cabecalho } from './../../models/cabecalho.model';
import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CabecalhoService {

  constructor(
    public api: ApiService,
  ) { }

  get(): Observable<any> {
    return this.api.get('/cabecalho/buscar');
  }

  patch(cabecalho: Cabecalho): Observable<any> {
    return this.api.post('/cabecalho/alterar', cabecalho);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

}
