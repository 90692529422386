import { Premio } from '../../models/premio.model';
import { ApiService } from './../api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SorteioService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<PremiosApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/premio/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/premio/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/premio/buscar?id=${id}`);
  }

  post(premio: Premio): Observable<any> {
    return this.api.post('/premio/inserir', premio);
  }

  patch(premio: Premio): Observable<any> {
    return this.api.post('/premio/alterar', premio);
  }

  push(premio: Premio) {
    return this.api.post(`/premio/notificacao`, premio);
  }

  pushGanhador(premio: Premio) {
    return this.api.post(`/premio/notificacaoGanhador`, premio);
  }

  delete(premio: Premio): Observable<any> {
    return this.api.post('/premio/deletar', premio);
  }

  deleteSelected(premios: Premio[]): Observable<any> {
    return this.api.post('/premio/deletarLista', premios);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  excel(): Observable<any> {
    return this.api.get(`/premio/excel`);
  }
}

export interface PremiosApi {
  premios: Premio[];
  numeroPaginas: number;
}

