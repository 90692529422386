import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Resposta } from 'src/app/models/resposta.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RespostaService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, idPergunta: number, filtro?: string): Observable<RespostasApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/resposta/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&idPergunta=${idPergunta}`;
    } else {
      requestUrl = `/resposta/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&idPergunta=${idPergunta}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/resposta/buscar?id=${id}`);
  }

  getSelect(idPergunta: number) {
    return this.api.get(`/select/resposta/buscar?idPergunta=${idPergunta}`);
  }

  post(resposta: Resposta): Observable<any> {
    return this.api.post('/resposta/inserir', resposta);
  }

  patch(resposta: Resposta): Observable<any> {
    return this.api.post('/resposta/alterar', resposta);
  }

  delete(resposta: Resposta): Observable<any> {
    return this.api.post('/resposta/deletar', resposta);
  }

  deleteSelected(respostas: Resposta[]): Observable<any> {
    return this.api.post('/resposta/deletarLista', respostas);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Resposta, v2: Resposta): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2
  }
}

export interface RespostasApi {
  respostas: Resposta[];
  numeroPaginas: number;
}