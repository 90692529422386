import { Liga } from './liga.model';
import { Jogo } from './jogo.model';
import { Pergunta } from './pergunta.model';
import { Resposta } from './resposta.model';

export class Partida {

    id: number;
    imagemEscudoAdversario: string = '';
    placarBlackDragons: number = null;
    placarAdversario: number = null;
    resultado: string = '';
    data: string = '';
    hora: string = '';
    jogo: Jogo = new Jogo();
    liga: Liga;
    pergunta: Pergunta = new Pergunta();
    resposta: Resposta = new Resposta();
    link: string = '';
    status: string = 'N';
    nomeAdversario: string = '';
    enviadoPushAntes: string = 'N';
    descricaoBattleRoyale: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}