import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { NgForm } from '@angular/forms';
import { HelperService } from './../../services/helper.service';
import { LinhaDoTempoService } from './../../services/class/linha-do-tempo.service';
import { LinhaDoTempo } from 'src/app/models/linha-do-tempo.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linha-do-tempo-criar',
  templateUrl: './linha-do-tempo-criar.component.html',
  styleUrls: ['./linha-do-tempo-criar.component.scss']
})
export class LinhaDoTempoCriarComponent implements OnInit {

  linhaDoTempo: LinhaDoTempo = new LinhaDoTempo();

  constructor(
    public linhaDoTempoService: LinhaDoTempoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  inserirLinhaDoTempo(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo linha do tempo...');

    this.linhaDoTempoService.post(this.linhaDoTempo)
      .subscribe((res: any) => {
        this.router.navigate(['/sobre/1']).then(() => {
          this.helper.openSnackBar('Linha do tempo inserida com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

}
