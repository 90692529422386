export class Jogo {

    id: number;
    nome: string = '';
    descricao: string = '';
    situacao: string = 'A';
    imagem: string = '';
    icone: string = '';
    slug: string = '';
    ordem: number = 99;
    battleRoyale: string = 'N';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}