import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalGaleriaService {

  active: boolean = false;
  activeSubject = new Subject();
  postSubject = new Subject();

  constructor() { }

  present() {
    this.active = true;
    this.activeSubject.next(true);
  }

  dismiss() {
    this.active = false;
    this.activeSubject.next(false);
  }
}
