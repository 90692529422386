export class Live {

    id: number;
    titulo: string = '';
    streamer: string = '';
    imagem: string = '';
    link: string = '';
    aoVivo: string = 'N';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}