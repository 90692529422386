import { Usuario } from './../../models/usuario.model';
import { UsuarioService } from './../../services/class/usuario.service';

// Default
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange, MatCheckboxChange } from '@angular/material';
import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-usuarios-criar',
  templateUrl: './usuarios-criar.component.html',
  styleUrls: ['./usuarios-criar.component.scss']
})
export class UsuariosCriarComponent implements OnInit {

  usuario: Usuario = new Usuario();

  file: File;
  progress: number = 0;

  constructor(
    public usuarioService: UsuarioService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
  }

  inserirUsuario(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    if (this.usuario.senha !== this.usuario.re_senha) {
      this.helper.openSnackBar('As senhas precisam ser iguais.');
      return;
    }

    this.loadingService.present('Inserindo usuário...');

    this.usuarioService.post(this.usuario)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/usuario/imagemPerfil?id=${res}`);
        this.router.navigate(['/usuarios']).then(() => {
          this.helper.openSnackBar('Usuário inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.usuario.situacao = 'A' : this.usuario.situacao = 'I';
  }

  onTestaNotificacaoChange(event: MatCheckboxChange) {
    event.checked ? this.usuario.testaNotificacao = 'S' : this.usuario.testaNotificacao = 'N';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/usuario/imagemPerfil?id=-99').then((res: any) => {
      this.usuario.imagemPerfil = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.usuarioService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.usuario.imagemPerfil = '';
  }

}
