export class Equipe {

    id: number;
    nome: string = '';
    cargo: string = '';
    email: string = '';
    linkFacebook: string = '';
    linkTwitter: string = '';
    linkInstagram: string = '';
    imagem: string = '';
    situacao: string = 'A';
    ordem: number = 99;
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}