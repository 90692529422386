import { HelperService } from './../../services/helper.service';
import { LoadingService } from './../../services/loading.service';
import { ConfiguracaoJogoService } from './../../services/class/configuracao-jogo.service';
import { ConfiguracaoJogo } from './../../models/configuracao-jogo.model';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { Caracteristica } from './../../models/caracteristica.model';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Equipamento } from './../../models/equipamento.model';
import { Component, OnInit, Injectable } from '@angular/core';

@Injectable()
export class listDatabase {
  dataChange = new BehaviorSubject<Equipamento[]>([]);

  get data(): Equipamento[] { return this.dataChange.value; }

  updateItem(node: any, valor: string) {
    node.valor = valor;
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-jogadores-configuracao',
  templateUrl: './jogadores-configuracao.component.html',
  styleUrls: ['./jogadores-configuracao.component.scss'],
  providers: [listDatabase]
})
export class JogadoresConfiguracaoComponent implements OnInit {

  flatNodeMap = new Map<any, any>();
  nestedNodeMap = new Map<any, any>();

  transformer = (node: any, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.nome
      ? existingNode
      : new Equipamento();

    flatNode.name = node.nome;
    flatNode.valor = node.valor;
    flatNode.level = level;
    flatNode.expandable = !!node.caracteristicas;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  treeControl = new FlatTreeControl<any>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level, node => node.expandable, node => node.caracteristicas);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  configuracao: ConfiguracaoJogo;

  buscarConfiguracaoJogoSubscription: Subscription;

  constructor(
    public configuracaoJogoService: ConfiguracaoJogoService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    private _database: listDatabase,
    public helper: HelperService,
  ) {
    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  hasChild = (_: number, node: Caracteristica) => node.expandable;

  ngOnInit() {
    this.route.params.subscribe(param => this.buscarConfiguracaoJogo(param.id));
  }

  ngOnDestroy() {
    this.buscarConfiguracaoJogoSubscription.unsubscribe();
  }

  changeValor(node: any, e) {
    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode!, e);
  }

  buscarConfiguracaoJogo(id: number) {
    this.buscarConfiguracaoJogoSubscription = this.configuracaoJogoService.getByJogador(id)
      .subscribe((res: ConfiguracaoJogo) => {
        this.configuracao = res;
        this._database.dataChange.next(res.equipamentos);
      })
  }

  alterarConfiguracaoJogo() {
    console.log(this._database.data);
    this.configuracao.equipamentos = this._database.data;
    this.loadingService.present('Alterando configurações...');
    this.configuracaoJogoService.patchJogador(this.configuracao, this.route.snapshot.params['id'])
      .subscribe((res: any) => {
        this.router.navigate(['/jogadores']).then(() => {
          this.helper.openSnackBar('Configurações do jogador alteradas com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => this.loadingService.dismiss());
  }

}
