import { Notificacao } from './../../models/notificacao.model';
import { Galeria } from './../../models/galeria.model';
import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<NotificacoesApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/notificacao/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/notificacao/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/notificacao/buscar?id=${id}`);
  }

  post(notificacao: Notificacao): Observable<any> {
    return this.api.post('/notificacao/inserir', notificacao);
  }

  patch(notificacao: Notificacao): Observable<any> {
    return this.api.post('/notificacao/alterar', notificacao);
  }

  delete(notificacao: Notificacao): Observable<any> {
    return this.api.post('/notificacao/deletar', notificacao);
  }

  deleteSelected(notificacoes: Notificacao[]): Observable<any> {
    return this.api.post('/notificacao/deletarLista', notificacoes);
  }

  push(notificacao: Notificacao, playerIdTeste?: string) {
    if (playerIdTeste) {
      return this.api.post(`/notificacao/enviarManual?playerIdTeste=${playerIdTeste}`, notificacao);
    } else {
      return this.api.post(`/notificacao/enviarManual`, notificacao);
    }
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  postImages(files: File[], url: string, body: Object) {
    return this.api.postAsFormData(url, body, files);
  }
}

export interface NotificacoesApi {
  notificacoes: Notificacao[];
  numeroPaginas: number;
}
