import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { NgForm } from '@angular/forms';
import { HelperService } from './../../services/helper.service';
import { LinhaDoTempoService } from './../../services/class/linha-do-tempo.service';
import { LinhaDoTempo } from 'src/app/models/linha-do-tempo.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linha-do-tempo-editar',
  templateUrl: './linha-do-tempo-editar.component.html',
  styleUrls: ['./linha-do-tempo-editar.component.scss']
})
export class LinhaDoTempoEditarComponent implements OnInit {

  linhaDoTempo: LinhaDoTempo = new LinhaDoTempo();
  buscarLinhaDoTempo: Subscription;

  loading: boolean = false;

  constructor(
    public linhaDoTempoService: LinhaDoTempoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(param => {
      this.buscarLinhaDoTempo = this.linhaDoTempoService.getById(param.id)
        .subscribe((res: LinhaDoTempo) => {
          this.linhaDoTempo = res;
          this.loading = false;
        }, e => this.loading = false);
    });
  }

  ngOnDestroy() {
    this.buscarLinhaDoTempo.unsubscribe();
  }

  alterarLinhaDoTempo(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando patrocinador...');

    this.linhaDoTempoService.patch(this.linhaDoTempo)
      .subscribe((res: any) => {
        this.router.navigate(['/sobre/1']).then(() => {
          this.helper.openSnackBar('Linha do tempo alterada com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

}
