import { Partida } from './../../models/partida.model';
import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class PartidaService {

  constructor(
    public api: ApiService,
    public auth: AuthService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, idLiga: any, idJogo: any, filtroTelaData: number, dataInicial: any, dataFinal: any, filtro?: string): Observable<PartidasApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/partida/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&idLiga=${idLiga}&idJogo=${idJogo}&filtroTelaData=${filtroTelaData}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`;
    } else {
      requestUrl = `/partida/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&idLiga=${idLiga}&idJogo=${idJogo}&filtroTelaData=${filtroTelaData}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`;
    }
    return this.api.get(requestUrl);
  }

  getCartola(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, idJogo: any = '%', jaRespondido: boolean = true, filtro?: string): Observable<PartidasApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/cartola/partida/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&idJogo=${idJogo}&jaRespondido=${jaRespondido}`;
    } else {
      requestUrl = `/cartola/partida/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}&idJogo=${idJogo}&jaRespondido=${jaRespondido}`;
    }
    return this.api.get(requestUrl);
  }

  getCartolaJogos() {
    return this.api.get(`/rankingJogo/buscarJogos`);
  }

  getById(id): Observable<any> {
    return this.api.get(`/partida/buscar?id=${id}`);
  }

  post(partida: Partida): Observable<any> {
    return this.api.post('/partida/inserir', partida);
  }

  patch(partida: Partida): Observable<any> {
    return this.api.post('/partida/alterar', partida);
  }

  delete(partida: Partida): Observable<any> {
    return this.api.post('/partida/deletar', partida);
  }

  deleteSelected(partidas: Partida[]): Observable<any> {
    return this.api.post('/partida/deletarLista', partidas);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  updateResposta(idPartida: number, idResposta: number,) {
    return this.api.get(`/partida/alterar/resposta?idPartida=${idPartida}&idResposta=${idResposta}`)
  }
}

export interface PartidasApi {
  partidas: Partida[];
  numeroPaginas: number;
}
