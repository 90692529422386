export class Notificacao {

    id: number;
    titulo: string = '';
    subTitulo: string = '';
    imagem: string = '';
    link: string = '';
    tipoNotificacao: string = 'M'; // (Manual ou automático)
    segunda: string = 'S'; // esconder manual
    terca: string = 'S'; // esconder manual
    quarta: string = 'S'; // esconder manual
    quinta: string = 'S'; // esconder manual
    sexta: string = 'S'; // esconder manual
    sabado: string = 'S'; // esconder manual
    domingo: string = 'S'; // esconder manual
    tipoHorario: string = 'F'; // esconder manual (Fixo ou automático)
    horaFixa: Date; // esconder manual
    horaInicialAutomatica: Date; // esconder manual
    horaFinalAutomatica: Date; // esconder manual
    dataInicial: Date = new Date(); // esconder manual
    dataFinal: Date = new Date(); // esconder manual
    situacao: string = 'A';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}