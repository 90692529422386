import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Pergunta } from 'src/app/models/pergunta.model';

@Injectable({
  providedIn: 'root'
})
export class PerguntaService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<PerguntasApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/pergunta/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/pergunta/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/pergunta/buscar?id=${id}`);
  }

  getSelect(idJogo: number) {
    return this.api.get(`/select/pergunta/buscar?idJogo=${idJogo}`);
  }

  post(pergunta: Pergunta): Observable<any> {
    return this.api.post('/pergunta/inserir', pergunta);
  }

  patch(pergunta: Pergunta): Observable<any> {
    return this.api.post('/pergunta/alterar', pergunta);
  }

  delete(pergunta: Pergunta): Observable<any> {
    return this.api.post('/pergunta/deletar', pergunta);
  }

  deleteSelected(perguntas: Pergunta[]): Observable<any> {
    return this.api.post('/pergunta/deletarLista', perguntas);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Pergunta, v2: Pergunta): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2
  }
}

export interface PerguntasApi {
  perguntas: Pergunta[];
  numeroPaginas: number;
}
