import { GlobalService } from './../../services/global.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  search: string = '';

  pages = [
    { name: 'Home', icon: 'fas fa-home', route: '/' },
    { name: 'apoiadores', icon: 'fas fa-user-tie', route: '/apoiadores' },
    { name: 'avatares', icon: 'fas fa-icons', route: '/avatares' },
    { name: 'banners', icon: 'fas fa-image', route: '/banners' },
    { name: 'cabeçalhos', icon: 'fas fa-image', route: '/cabecalhos' },
    { name: 'comunidade', icon: 'fas fa-image', route: '/comunidade' },
    { name: 'configurações', icon: 'fas fa-cog', route: '/configuracoes' },
    { name: 'contato', icon: 'fas fa-phone-alt', route: '/contato' },
    { name: 'equipe', icon: 'fas fa-praying-hands', route: '/equipe' },
    { name: 'jogadores', icon: 'fas fa-male', route: '/jogadores' },
    { name: 'jogos', icon: 'fas fa-gamepad', route: '/jogos' },
    { name: 'ligas', icon: 'fas fa-trophy', route: '/ligas' },
    { name: 'lives', icon: 'fas fa-tv', route: '/lives' },
    { name: 'mensagens', icon: 'fas fa-envelope', route: '/mensagens' },
    { name: 'noticias', icon: 'fas fa-newspaper', route: '/noticias' },
    { name: 'notificaçoes', icon: 'fas fa-bell', route: '/notificacoes' },
    { name: 'palpitei', icon: 'fas fa-dice', route: '/palpitei' },
    { name: 'partidas', icon: 'fas fa-dice', route: '/partidas' },
    { name: 'patrocinadores', icon: 'fas fa-user-tie', route: '/patrocinadores' },
    { name: 'perguntas', icon: 'fas fa-question', route: '/perguntas' },
    { name: 'produtos', icon: 'fas fa-box-open', route: '/produtos' },
    { name: 'sobre', icon: 'fas fa-info', route: '/sobre' },
    { name: 'prêmios', icon: 'fas fa-award', route: '/premios' },
    { name: 'times', icon: 'fas fa-users', route: '/times' },
    { name: 'usuários', icon: 'fas fa-user-friends', route: '/usuarios' },
  ]

  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  goTo(page: string) {
    this.router.navigate([page]).then(() => {
      this.global.menuOpen = false;
    });
  }

  logout() {
    this.router.navigate(['/login']).then(() => {
      this.global.menuOpen = false;
      this.auth.logout();
    })
  }

}
