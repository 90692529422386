import { Jogo } from './jogo.model';
export class Galeria {

    id: number;
    tipo: string = 'F';
    jogo: Jogo = new Jogo();
    imagem: string = '';
    plataforma: string = 'D';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}