import { Galeria } from './../../models/galeria.model';
import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GaleriaService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<GaleriasApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/galeria/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/galeria/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/galeria/buscar?id=${id}`);
  }

  post(galeria: Galeria): Observable<any> {
    return this.api.post('/galeria/inserir', galeria);
  }

  patch(galeria: Galeria): Observable<any> {
    return this.api.post('/galeria/alterar', galeria);
  }

  delete(galeria: Galeria): Observable<any> {
    return this.api.post('/galeria/deletar', galeria);
  }

  deleteSelected(galerias: Galeria[]): Observable<any> {
    return this.api.post('/galeria/deletarLista', galerias);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  postImages(files: File[], url: string, body: Object) {
    return this.api.postAsFormData(url, body, files);
  }
}

export interface GaleriasApi {
  galerias: Galeria[];
  numeroPaginas: number;
}
