import { Noticia } from './../../models/noticia.model';
import { ApiService } from './../api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NoticiaService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<NoticiasApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/noticia/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/noticia/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/noticia/buscar?id=${id}`);
  }

  post(noticia: Noticia): Observable<any> {
    return this.api.post('/noticia/inserir', noticia);
  }

  patch(noticia: Noticia): Observable<any> {
    return this.api.post('/noticia/alterar', noticia);
  }

  delete(noticia: Noticia): Observable<any> {
    return this.api.post('/noticia/deletar', noticia);
  }

  push(noticia: Noticia) {
    return this.api.post(`/noticia/notificacao`, noticia);
  }

  deleteSelected(noticias: Noticia[]): Observable<any> {
    return this.api.post('/noticia/deletarLista', noticias);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

}

export interface NoticiasApi {
  noticias: Noticia[];
  numeroPaginas: number;
}
