export class Produto {

    id: number;
    nome: string;
    descricao: string;
    situacao: string = 'A';
    imagem: string = '';
    valor: number;
    link: string = '';
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}