import { ConfiguracaoJogo } from './../../models/configuracao-jogo.model';
import { ApiService } from './../api.service';
import { Observable } from 'rxjs';
import { Jogo } from './../../models/jogo.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoJogoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<ConfiguracoesApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/configuracaoEmJogo/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/configuracaoEmJogo/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/configuracaoEmJogo/buscar?idJogo=${id}`);
  }

  patch(configuracaoJogo: ConfiguracaoJogo): Observable<any> {
    return this.api.post('/configuracaoEmJogo/alterar', configuracaoJogo);
  }

  exist(jogo: Jogo): Observable<any> {
    return this.api.get(`/configuracaoEmJogo/existe?idJogo=${jogo.id}`);
  }

  getByJogador(idJogador: number): Observable<any> {
    return this.api.get(`/configuracaoEmJogo/buscar/jogador?idJogador=${idJogador}`)
  }

  patchJogador(configuracaoJogo: ConfiguracaoJogo, idJogador: number): Observable<any> {
    return this.api.post(`/configuracaoEmJogo/alterar/valor?idJogador=${idJogador}`, configuracaoJogo);
  }

}

export interface ConfiguracoesApi {
  configuracoes: ConfiguracaoJogo[];
  numeroPaginas: number;
}

