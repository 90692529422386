import { Jogo } from './jogo.model';

export class Premio {

    id: number;
    data: Date;
    premio: string = '';
    imagemPremio: string = '';
    observacao: string = '';
    idUsuarioGanhador: number = null;
    nomeGanhador: string = '';
    enviado: string = 'N';
    codigoEnvio: string = '';
    jogo: Jogo;
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}