import { LigaService, LigasApi } from './../../services/class/liga.service';
import { Liga } from './../../models/liga.model';
import { Subscription, SubscriptionLike } from 'rxjs';
import { JogoService, JogosApi } from './../../services/class/jogo.service';
import { Jogo } from './../../models/jogo.model';
import { PartidaService } from './../../services/class/partida.service';
import { Partida } from './../../models/partida.model';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange, MatCheckboxChange, MatSelectChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { RespostaService } from 'src/app/services/class/resposta.service';
import { PerguntaService } from 'src/app/services/class/pergunta.service';
import { Pergunta } from 'src/app/models/pergunta.model';
import { Resposta } from 'src/app/models/resposta.model';

@Component({
  selector: 'app-partidas-editar',
  templateUrl: './partidas-editar.component.html',
  styleUrls: ['./partidas-editar.component.scss']
})
export class PartidasEditarComponent implements OnInit {

  @ViewChild('editorElement', { static: false }) editorElement: CKEditorComponent;

  partida: Partida = new Partida();
  editor;

  jogos: Jogo[] = [];
  ligas: Liga[] = [];
  perguntas: Pergunta[] = [];
  respostas: Resposta[] = [];

  data;
  hora;

  file: File;
  progress: any;

  buscarPartidaSubscription: Subscription;
  buscarJogosSubscription: Subscription;
  buscarLigasSubscription: Subscription;

  loading: boolean = false;

  constructor(
    public helper: HelperService,
    public partidaService: PartidaService,
    public jogoService: JogoService,
    public ligaService: LigaService,
    public repostaService: RespostaService,
    public perguntaService: PerguntaService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(param => {
      this.buscarPartidaSubscription = this.partidaService.getById(param.id)
        .subscribe((res: Partida) => {
          this.partida = res;
          this.data = this.helper.moment(res.data).format('DD/MM/YYYY');
          this.hora = this.helper.moment(res.hora).format('HH:mm');
          this.perguntaService.getSelect(res.jogo.id)
            .subscribe((res: Pergunta[]) => {
              this.perguntas = res;
            });
          if (res.pergunta.id) {
            this.onPerguntaChange(res.pergunta);
          }
          this.loading = false;
        }, e => this.loading = false);

    });

    this.buscarJogosSubscription = this.jogoService.get(-99, -99, '1', 'ASC')
      .subscribe((res: JogosApi) => this.jogos = res.jogos);

    this.buscarLigasSubscription = this.ligaService.getAtivas()
      .subscribe((res: Liga[]) => this.ligas = res);
  }

  ngOnDestroy() {
    this.buscarPartidaSubscription.unsubscribe();
    this.buscarJogosSubscription.unsubscribe();
    this.buscarLigasSubscription.unsubscribe();
  }

  alterarPartida(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando partida...');
    this.partida.data = this.helper.moment(this.data, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.partida.hora = `${this.helper.moment().format('YYYY-MM-DD')} ${this.hora}:00`;
    this.partidaService.patch(this.partida)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/partida/imagemEscudoAdversario?id=${this.partida.id}`);
        this.router.navigate(['/partidas']).then(() => {
          this.helper.openSnackBar('Partida alterada com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.partida.situacao = 'A' : this.partida.situacao = 'I';
  }

  onAoVivoChange(event: MatCheckboxChange) {
    event.checked ? this.partida.aoVivo = 'S' : this.partida.aoVivo = 'N';
  }

  onPerguntaChange(ev: Pergunta) {
    this.repostaService.getSelect(ev.id)
      .subscribe((res: Resposta[]) => this.respostas = res);
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/partida/imagemEscudoAdversario?id=-99').then((res: any) => {
      this.partida.imagemEscudoAdversario = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.partidaService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.partida.imagemEscudoAdversario = '';
  }

}
