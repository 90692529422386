import { Mensagem } from './../../models/mensagem.model';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContatoService {

  constructor(
    private api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<MensagensApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/contatoMensagem/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/contatoMensagem/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get('/contatoMensagem/buscar?id=' + id);
  }

  post(mensagem: Mensagem): Observable<any> {
    return this.api.post('/contatoMensagem/inserir', mensagem);
  }

  patch(mensagem: Mensagem): Observable<any> {
    return this.api.post('/contatoMensagem/alterar', mensagem);
  }

  delete(mensagem: Mensagem): Observable<any> {
    return this.api.post('/contatoMensagem/deletar', mensagem);
  }

  deleteSelected(mensagens: Mensagem[]): Observable<any> {
    return this.api.post('/contatoMensagem/deletarLista', mensagens);
  }

  excel(): Observable<any> {
    return this.api.get(`/contatoMensagem/excel`);
  }

  buscarGeral() {
    return this.api.get('/contatoGeral/buscar');
  }

  alterarGeral(contatoGeral: any) {
    return this.api.post('/contatoGeral/alterar', contatoGeral);
  }
}

export interface MensagensApi {
  ContatosMensagem: Mensagem[];
  numeroPaginas: number;
}
