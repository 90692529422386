import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Avatar } from 'src/app/models/avatar.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<AvatarsApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/avatar/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/avatar/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/avatar/buscar?id=${id}`);
  }

  post(avatar: Avatar): Observable<any> {
    return this.api.post('/avatar/inserir', avatar);
  }

  patch(avatar: Avatar): Observable<any> {
    return this.api.post('/avatar/alterar', avatar);
  }

  delete(avatar: Avatar): Observable<any> {
    return this.api.post('/avatar/deletar', avatar);
  }

  deleteSelected(avatars: Avatar[]): Observable<any> {
    return this.api.post('/avatar/deletarLista', avatars);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface AvatarsApi {
  avatares: Avatar[];
  numeroPaginas: number;
}

