import { Jogo } from './jogo.model';

export class Liga {

    id: number;
    nome: string = '';
    imagem: string = '';
    situacao: string = 'A';
    cartola: string = 'N';
    jogo: Jogo;
    
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}