import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  moment;
  editor;
  emailRegex: RegExp;
  urlRegex: RegExp;

  config = {
    placeholder: '',
    tabsize: 2,
    height: '500px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
  }

  constructor(
    private _snackBar: MatSnackBar
  ) {
    moment.locale('pt-BR');
    this.moment = moment;
    this.editor = ClassicEditor;
    this.emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    this.urlRegex = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3200,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['white-snackbar']
    });
  }

  formMarkAllTouched(form: NgForm) {
    (<any>Object).values(form.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        (<any>Object).values(control.controls).forEach(c => this.formMarkAllTouched(c));
      }
    });
  }

  convertToSlug(string: string) {
    return string.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  }
}
