import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Pergunta } from 'src/app/models/pergunta.model';
import { Subscription } from 'rxjs';
import { Jogo } from 'src/app/models/jogo.model';
import { JogoService, JogosApi } from 'src/app/services/class/jogo.service';
import { HelperService } from 'src/app/services/helper.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Router } from '@angular/router';
import { PerguntaService } from 'src/app/services/class/pergunta.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-perguntas-criar',
  templateUrl: './perguntas-criar.component.html',
  styleUrls: ['./perguntas-criar.component.scss']
})
export class PerguntasCriarComponent implements OnInit {

  pergunta: Pergunta = new Pergunta();

  jogos: Jogo[] = [];
  buscarJogosSubscription: Subscription;

  file: File;
  progress: number = 0;

  constructor(
    public jogoService: JogoService,
    public helper: HelperService,
    public perguntaService: PerguntaService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
    this.buscarJogos();
  }

  ngOnDestroy() {
    this.buscarJogosSubscription.unsubscribe();
  }

  buscarJogos() {
    this.buscarJogosSubscription = this.jogoService.get(-99, -99, '1', 'ASC')
      .subscribe((res: JogosApi) => {
        this.jogos = res.jogos;
      });
  }

  inserirPergunta(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo pergunta...');

    this.perguntaService.post(this.pergunta)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/pergunta/imagem?id=${res}`);
        this.router.navigate(['/perguntas/editar', res]).then(() => {
          this.helper.openSnackBar('Pergunta inserida com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.pergunta.situacao = 'A' : this.pergunta.situacao = 'I';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('Alterando imagem...');
    this.submitImages(this.file, '/pergunta/imagem?id=-99').then((res: any) => {
      this.pergunta.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.perguntaService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.pergunta.imagem = '';
  }

}
