import { LoadingService } from './../../services/loading.service';
import { NotificacaoService } from './../../services/class/notificacao.service';
import { Usuario } from './../../models/usuario.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuarioService } from './../../services/class/usuario.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Notificacao } from 'src/app/models/notificacao.model';
import { NgForm } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-modal-notificacao',
  templateUrl: './modal-notificacao.component.html',
  styleUrls: ['./modal-notificacao.component.scss']
})
export class ModalNotificacaoComponent implements OnInit {

  playerId: string = '';
  notificao: Notificacao = new Notificacao();
  usuarios: Usuario[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalNotificacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public usuarioService: UsuarioService,
    public notificacaoService: NotificacaoService,
    public loadingService: LoadingService,
    public helper: HelperService
  ) { }

  ngOnInit() {
    this.buscarPlayerIds();
    this.notificao = this.data.notificacao;
  }

  buscarPlayerIds() {
    this.usuarioService.getPlayerId().subscribe((res: Usuario[]) => this.usuarios = res)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  enviarManual() {

    if (!this.playerId) {
      this.helper.openSnackBar('Preencha todos os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Enviando push...');

    this.notificacaoService.push(this.notificao, this.playerId).subscribe((res: any) => {
      this.loadingService.dismiss();
      this.dialogRef.close();
    }, e => this.loadingService.dismiss());
  }


}
