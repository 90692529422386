import { ApiService } from './../api.service';
import { Observable } from 'rxjs';
import { Jogo } from './../../models/jogo.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JogoService {

  constructor(
    public api: ApiService
  ) { }

  get(numeroPagina: number, registroPorPagina: number, ordenacao: string, sentidoOrdenacao: string, filtro?: string): Observable<JogosApi> {
    let requestUrl: string;
    if (filtro) {
      requestUrl = `/jogo/buscar?filtro=${filtro}&numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    } else {
      requestUrl = `/jogo/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&ordenacao=${ordenacao}&sentidoOrdenacao=${sentidoOrdenacao}`;
    }
    return this.api.get(requestUrl);
  }

  getById(id): Observable<any> {
    return this.api.get(`/jogo/buscar?id=${id}`);
  }

  getRanking() {
    return this.api.get('/rankingJogo/buscarJogos');
  }

  getSelect() {
    return this.api.get('/select/jogo/buscar');
  }

  post(jogo: Jogo): Observable<any> {
    return this.api.post('/jogo/inserir', jogo);
  }

  patch(jogo: Jogo): Observable<any> {
    return this.api.post('/jogo/alterar', jogo);
  }

  delete(jogo: Jogo): Observable<any> {
    return this.api.post('/jogo/deletar', jogo);
  }

  deleteSelected(jogos: Jogo[]): Observable<any> {
    return this.api.post('/jogo/deletarLista', jogos);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Jogo, v2: Jogo): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2
  }
}

export interface JogosApi {
  jogos: Jogo[];
  numeroPaginas: number;
}
