import { NotificacoesCriarComponent } from './pages/notificacoes-criar/notificacoes-criar.component';
import { NotificacoesComponent } from './pages/notificacoes/notificacoes.component';
import { SorteiosEditarComponent } from './pages/sorteios-editar/sorteios-editar.component';
import { SorteiosCriarComponent } from './pages/sorteios-criar/sorteios-criar.component';
import { SorteiosComponent } from './pages/sorteios/sorteios.component';
import { CabecalhosComponent } from './pages/cabecalhos/cabecalhos.component';
import { ApoiadoresEditarComponent } from './pages/apoiadores-editar/apoiadores-editar.component';
import { ApoiadoresCriarComponent } from './pages/apoiadores-criar/apoiadores-criar.component';
import { ApoiadoresComponent } from './pages/apoiadores/apoiadores.component';
import { JogadoresConfiguracaoComponent } from './pages/jogadores-configuracao/jogadores-configuracao.component';
import { JogosConfiguracaoComponent } from './pages/jogos-configuracao/jogos-configuracao.component';
import { LivesEditarComponent } from './pages/lives-editar/lives-editar.component';
import { LivesCriarComponent } from './pages/lives-criar/lives-criar.component';
import { LivesComponent } from './pages/lives/lives.component';
import { LigasEditarComponent } from './pages/ligas-editar/ligas-editar.component';
import { LigasCriarComponent } from './pages/ligas-criar/ligas-criar.component';
import { LigasComponent } from './pages/ligas/ligas.component';
import { ComunidadeEditarComponent } from './pages/comunidade-editar/comunidade-editar.component';
import { ComunidadeCriarComponent } from './pages/comunidade-criar/comunidade-criar.component';
import { ComunidadeComponent } from './pages/comunidade/comunidade.component';
import { MensagensComponent } from './pages/mensagens/mensagens.component';
import { LinhaDoTempoEditarComponent } from './pages/linha-do-tempo-editar/linha-do-tempo-editar.component';
import { LinhaDoTempoCriarComponent } from './pages/linha-do-tempo-criar/linha-do-tempo-criar.component';
import { EquipeEditarComponent } from './pages/equipe-editar/equipe-editar.component';
import { EquipeCriarComponent } from './pages/equipe-criar/equipe-criar.component';
import { EquipeComponent } from './pages/equipe/equipe.component';
import { PatrocinadoresEditarComponent } from './pages/patrocinadores-editar/patrocinadores-editar.component';
import { PatrocinadoresCriarComponent } from './pages/patrocinadores-criar/patrocinadores-criar.component';
import { PatrocinadoresComponent } from './pages/patrocinadores/patrocinadores.component';
import { NoticiasEditarComponent } from './pages/noticias-editar/noticias-editar.component';
import { NoticiasCriarComponent } from './pages/noticias-criar/noticias-criar.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { ProdutosEditarComponent } from './pages/produtos-editar/produtos-editar.component';
import { ProdutosCriarComponent } from './pages/produtos-criar/produtos-criar.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { BannersEditarComponent } from './pages/banners-editar/banners-editar.component';
import { BannersCriarComponent } from './pages/banners-criar/banners-criar.component';
import { BannersComponent } from './pages/banners/banners.component';
import { PartidasEditarComponent } from './pages/partidas-editar/partidas-editar.component';
import { PartidasCriarComponent } from './pages/partidas-criar/partidas-criar.component';
import { PartidasComponent } from './pages/partidas/partidas.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { JogadoresEditarComponent } from './pages/jogadores-editar/jogadores-editar.component';
import { JogadoresCriarComponent } from './pages/jogadores-criar/jogadores-criar.component';
import { JogadoresComponent } from './pages/jogadores/jogadores.component';
import { TimesEditarComponent } from './pages/times-editar/times-editar.component';
import { TimesCriarComponent } from './pages/times-criar/times-criar.component';
import { TimesComponent } from './pages/times/times.component';
import { JogosEditarComponent } from './pages/jogos-editar/jogos-editar.component';
import { JogosCriarComponent } from './pages/jogos-criar/jogos-criar.component';
import { JogosComponent } from './pages/jogos/jogos.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { NotificacoesEditarComponent } from './pages/notificacoes-editar/notificacoes-editar.component';
import { PerguntasComponent } from './pages/perguntas/perguntas.component';
import { PerguntasEditarComponent } from './pages/perguntas-editar/perguntas-editar.component';
import { PerguntasCriarComponent } from './pages/perguntas-criar/perguntas-criar.component';
import { CartolaComponent } from './pages/cartola/cartola.component';
import { AvatarsComponent } from './pages/avatars/avatars.component';
import { AvatarsEditarComponent } from './pages/avatars-editar/avatars-editar.component';
import { AvatarsCriarComponent } from './pages/avatars-criar/avatars-criar.component';


const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent, canActivate: [IsLoggedInGuard] },
  { path: 'sobre/:tab', component: SobreComponent, canActivate: [IsLoggedInGuard] },
  { path: 'cabecalhos', component: CabecalhosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'contato', component: ContatoComponent, canActivate: [IsLoggedInGuard] },
  { path: 'mensagens', component: MensagensComponent, canActivate: [IsLoggedInGuard] },
  { path: 'configuracoes', component: ConfiguracoesComponent, canActivate: [IsLoggedInGuard] },
  { path: 'banners', component: BannersComponent, canActivate: [IsLoggedInGuard] },
  { path: 'banners/adicionar', component: BannersCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'banners/editar/:id', component: BannersEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'noticias', component: NoticiasComponent, canActivate: [IsLoggedInGuard] },
  { path: 'noticias/adicionar', component: NoticiasCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'noticias/editar/:id', component: NoticiasEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'produtos', component: ProdutosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'produtos/adicionar', component: ProdutosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'produtos/editar/:id', component: ProdutosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'jogos', component: JogosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'jogos/adicionar', component: JogosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'jogos/editar/:id', component: JogosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'jogos/configuracao/:id', component: JogosConfiguracaoComponent, canActivate: [IsLoggedInGuard] },
  { path: 'times', component: TimesComponent, canActivate: [IsLoggedInGuard] },
  { path: 'times/adicionar', component: TimesCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'times/editar/:id', component: TimesEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'jogadores', component: JogadoresComponent, canActivate: [IsLoggedInGuard] },
  { path: 'jogadores/adicionar', component: JogadoresCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'jogadores/editar/:id', component: JogadoresEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'jogadores/configuracao/:id', component: JogadoresConfiguracaoComponent, canActivate: [IsLoggedInGuard] },
  { path: 'patrocinadores', component: PatrocinadoresComponent, canActivate: [IsLoggedInGuard] },
  { path: 'patrocinadores/adicionar', component: PatrocinadoresCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'patrocinadores/editar/:id', component: PatrocinadoresEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'apoiadores', component: ApoiadoresComponent, canActivate: [IsLoggedInGuard] },
  { path: 'apoiadores/adicionar', component: ApoiadoresCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'apoiadores/editar/:id', component: ApoiadoresEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios', component: UsuariosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios/adicionar', component: UsuariosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios/editar/:id', component: UsuariosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'partidas', component: PartidasComponent, canActivate: [IsLoggedInGuard] },
  { path: 'partidas/adicionar', component: PartidasCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'partidas/editar/:id', component: PartidasEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'equipe', component: EquipeComponent, canActivate: [IsLoggedInGuard] },
  { path: 'equipe/adicionar', component: EquipeCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'equipe/editar/:id', component: EquipeEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'comunidade', component: ComunidadeComponent, canActivate: [IsLoggedInGuard] },
  { path: 'comunidade/adicionar', component: ComunidadeCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'comunidade/editar/:id', component: ComunidadeEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'notificacoes', component: NotificacoesComponent, canActivate: [IsLoggedInGuard] },
  { path: 'notificacoes/adicionar', component: NotificacoesCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'notificacoes/editar/:id', component: NotificacoesEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'ligas', component: LigasComponent, canActivate: [IsLoggedInGuard] },
  { path: 'ligas/adicionar', component: LigasCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'ligas/editar/:id', component: LigasEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'lives', component: LivesComponent, canActivate: [IsLoggedInGuard] },
  { path: 'lives/adicionar', component: LivesCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'lives/editar/:id', component: LivesEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'linha-do-tempo/adicionar', component: LinhaDoTempoCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'linha-do-tempo/editar/:id', component: LinhaDoTempoEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'perguntas', component: PerguntasComponent, canActivate: [IsLoggedInGuard] },
  { path: 'perguntas/adicionar', component: PerguntasCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'perguntas/editar/:id', component: PerguntasEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'avatares', component: AvatarsComponent, canActivate: [IsLoggedInGuard] },
  { path: 'avatares/adicionar', component: AvatarsCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'avatares/editar/:id', component: AvatarsEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'premios', component: SorteiosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'premios/adicionar', component: SorteiosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'premios/editar/:id', component: SorteiosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'palpitei', component: CartolaComponent, canActivate: [IsLoggedInGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
