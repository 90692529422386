import { Component, OnInit, Inject } from '@angular/core';
import { Partida } from 'src/app/models/partida.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';
import { Pergunta } from 'src/app/models/pergunta.model';
import { LoadingService } from 'src/app/services/loading.service';
import { PartidaService } from 'src/app/services/class/partida.service';

@Component({
  selector: 'app-modal-cartola',
  templateUrl: './modal-cartola.component.html',
  styleUrls: ['./modal-cartola.component.scss']
})
export class ModalCartolaComponent implements OnInit {

  partida: Partida = new Partida();
  pergunta: Pergunta = new Pergunta();

  idResposta: number;

  constructor(
    public dialogRef: MatDialogRef<ModalCartolaComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Partida,
    public api: ApiService,
    public partidaService: PartidaService,
    public loadingService: LoadingService
  ) {
    this.partida = this.data;
  }

  ngOnInit() {
    this.buscarPerguntaCompleta(this.partida.pergunta.id, this.partida.id)
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick() {
    this.loadingService.present('Alterando resposta...');
    this.partidaService.updateResposta(this.partida.id, this.idResposta)
      .subscribe(res => {
        this.dialogRef.close(true);
        this.loadingService.dismiss();
      }, e => {
        this.dialogRef.close();
        this.loadingService.dismiss();
      })
  }

  buscarPerguntaCompleta(idPergunta: number, idPartida: number) {
    this.api.get(`/pergunta/buscar/completo?idPergunta=${idPergunta}&idPartida=${idPartida}`)
      .subscribe((res: any) => {
        console.log(res);
        this.pergunta = res;
        const resposta = this.pergunta.respostas.find(r => r.marcado === 'S');
        if (resposta) {
          this.idResposta = resposta.id;
        }
      })
  }

}
