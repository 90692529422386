import { Subscription } from 'rxjs';
import { LiveService } from './../../services/class/live.service';
import { Live } from './../../models/live.model';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange, MatCheckboxChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lives-editar',
  templateUrl: './lives-editar.component.html',
  styleUrls: ['./lives-editar.component.scss']
})
export class LivesEditarComponent implements OnInit {

  live: Live = new Live();

  buscarLiveSubscription: Subscription

  file: File;
  progress: any;

  loading: boolean = false;

  constructor(
    public helper: HelperService,
    public liveService: LiveService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.loading = true;
      this.buscarLiveSubscription = this.liveService.getById(param.id)
        .subscribe((res: Live) => {
          this.live = res;
          this.loading = false;
        }, e => this.loading = false);
    })
  }

  alterarLive(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando live...');

    this.liveService.patch(this.live)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/live/imagem?id=${this.live.id}`);
        this.router.navigate(['/lives']).then(() => {
          this.helper.openSnackBar('Live alterada com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.live.situacao = 'A' : this.live.situacao = 'I';
  }

  onAoVivoChange(event: MatCheckboxChange) {
    event.checked ? this.live.aoVivo = 'S' : this.live.aoVivo = 'N';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/live/imagem?id=-99').then((res: any) => {
      this.live.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.liveService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.live.imagem = '';
  }

}
