import { Subscription } from 'rxjs';
import { ApoiadorService } from './../../services/class/apoiador.service';
import { Apoiador } from './../../models/apoiador.model';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-apoiadores-editar',
  templateUrl: './apoiadores-editar.component.html',
  styleUrls: ['./apoiadores-editar.component.scss']
})
export class ApoiadoresEditarComponent implements OnInit {

  apoiador: Apoiador = new Apoiador();

  buscarApoiadorSubscription: Subscription;

  file: File;
  fileBackground: File;

  progress: any;

  loading: boolean = false;

  constructor(
    public helper: HelperService,
    public apoiadorService: ApoiadorService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.loading = true;
      this.buscarApoiadorSubscription = this.apoiadorService.getById(param.id)
        .subscribe((res: Apoiador) => {
          this.apoiador = res;
          this.loading = false;
        }, e => this.loading = false);
    })
  }

  ngOnDestroy() {
    this.buscarApoiadorSubscription.unsubscribe();
  }

  alterarApoiador(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo apoiador...');

    this.apoiadorService.patch(this.apoiador)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/apoiador/imagem?id=${this.apoiador.id}`);
        this.submitImages(this.file, `/apoiador/background?id=${this.apoiador.id}`);
        this.router.navigate(['/apoiadores']).then(() => {
          this.helper.openSnackBar('Apoiador inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.apoiador.situacao = 'A' : this.apoiador.situacao = 'I';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/apoiador/imagem?id=-99').then((res: any) => {
      this.apoiador.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  @ViewChild('fileInputBackground', { static: true }) fileInputBackground: ElementRef;
  onFileBackgroundSelected(files) {
    this.fileBackground = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileBackground, '/apoiador/background?id=-99').then((res: any) => {
      this.apoiador.background = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInputBackground.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.apoiadorService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.apoiador.imagem = '';
  }

  removeBackground() {
    this.apoiador.background = '';
  }

}
